import type { ClassValue } from 'clsx'
import type { FunctionComponent, PropsWithChildren, ReactNode } from 'react'

import { Advertisement, AdvertisementGroup } from '@sporza/design-system/molecules/advertisement'
import Container from '@sporza/design-system/organisms/container'
import styles from '@sporza/design-system/organisms/container/container.module.scss'
import clsx from 'clsx'

interface LayoutProps extends PropsWithChildren {
  headerChildren?: ReactNode
  className?: ClassValue
}

const Layout: FunctionComponent<LayoutProps> = (
  {
    headerChildren,
    children,
    className
  }
) => {
  return <>
    {
      headerChildren
        ? <Container
          tag="header" className={clsx(
            styles.collapsed,
            className
        )}
        >
          {headerChildren}
        </Container>
        : false
    }
    <Container className={className}>
      {children}

      <AdvertisementGroup>
        <Advertisement adType="cba"/>
        <Advertisement adType="cba"/>
        <Advertisement adType="cba"/>
        <Advertisement adType="cba"/>
      </AdvertisementGroup>
    </Container>
  </>
}

export {
  Layout
}
